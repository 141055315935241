// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import 'jquery'
import 'stylesheets/application'

import controller from './application_controller'
import debounced from 'debounced'

import 'bootstrap'


debounced.initialize({ input: { wait: 325 } })
const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)

application.consumer = consumer
application.load(definitionsFromContext(context))
application.register("shop-search", controller)
application.register("adwords-search", controller)
StimulusReflex.initialize(application, { controller, debug: true, isolate: true })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'






