import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'loadingDataFeed',
    'loadingCampaign',
    'reuploadDataFeed',
    'reuploadAllDataFeed'
  ]

  beforeReflex(element, reflex) {
    super.beforeReflex(element, reflex)
    if (reflex === 'Admin::Shopping::Shopping#create_data_feed') {
      this.loadingDataFeedTarget.disabled = true;
      this.loadingDataFeedTarget.innerHTML = "<p style='display: flex; align-items:center'>Uploading... <span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span></p>";
      // this.loadingDataFeedTarget.innerText = "Uploading...<span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span>";
    }

    if (reflex === 'Admin::Shopping::Shopping#create_campaign') {
      this.loadingCampaignTarget.disabled = true;
      this.loadingCampaignTarget.innerHTML = "<div style='display: flex;'><p>Creating...</p><span class='spinner-border spinner-border-sm ml-2' role='status' aria-hidden='true'></span></div>";
      // this.loadingCampaignTarget.innerText = "Uploading...<span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span>";
    }

    if (reflex === 'Admin::Shopping::Shopping#reupload_data_feed') {
      element.disabled = true;
      element.innerHTML = "<p style='display: flex; align-items:center'>Uploading... <span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span></p>";
    }

    if (reflex === 'Admin::Shopping::Shopping#reupload_all_feeds') {
      this.reuploadAllDataFeedTarget.disabled = true;
      this.reuploadAllDataFeedTarget.innerHTML = "<p style='display: flex; align-items:center'>Uploading... <span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span></p>";
    }
  }
}