import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'loadingUpdateStatus',
  ]

  beforeReflex (element, reflex) {
    super.beforeReflex(element, reflex)
    if (reflex === 'KeywordsReflex#update_status')
      this.loadingUpdateStatusTarget.hidden = false;
  }
}