import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'checkTemplatesVariables'
  ]

  beforeReflex(element, reflex) {
    super.beforeReflex(element, reflex)
    if (reflex === 'MailingReflex#show_check_templates_variables_modal') {
      this.checkTemplatesVariablesTarget.disabled = true;
      this.checkTemplatesVariablesTarget.innerHTML = "<div style='display: flex; align-items:center; gap:5px; font-size: inherit'>LOADING...<span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span></div>";
    }
  }

  afterReflex(element, reflex) {
    super.afterReflex(element, reflex)
    if (reflex === 'MailingReflex#show_check_templates_variables_modal') {
      this.checkTemplatesVariablesTarget.disabled = false;
      this.checkTemplatesVariablesTarget.innerHTML = "Check template variables";
    }
  }
}