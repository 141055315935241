import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  open_modal(element) {
    console.log(element.srcElement.dataset.id);
    this.stimulate('Admin::Clients::CookerTasksOverview#show_modal', { resolveLate: true }).then(() => {
      console.log('The Reflex has been finalized.')
    })
  }

  reflexHalted(el) {
    console.log(this)
  }

  reflexError() {
    console.log(this)
  }
}