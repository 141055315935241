import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  getSpinnerElement() {
    const element = document.getElementById('spinner-container');
    return element ?? false;
  }
  showSpinner() {
    const spinner = this.getSpinnerElement();
    if (spinner) {
      spinner.style.display = 'block'
    }
  }

  hideSpinner() {
    const spinner = this.getSpinnerElement();
    if (spinner) {
      spinner.style.display = 'none'
    }
  }

  beforeReflex (element, reflex, noop, reflexId) {
    if (element.dataset.showSpinner === "true") {
      this.showSpinner()
    }
  }

  finalizeReflex (element, reflex, controller, reflexId) {
    if (element.dataset.showSpinner === "true") {
      this.hideSpinner()
    }
  }



}
