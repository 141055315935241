import Flatpickr from 'stimulus-flatpickr'
import 'flatpickr/dist/themes/dark.css'
export default class extends Flatpickr {
  initialize () {
    this.element[this.identifier] = this
    this.config = {
      enableTime: false,
      locale: {
        firstDayOfWeek: 1
    }
    }
  }
}