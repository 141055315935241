import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'createB2BShop'
  ]

  beforeReflex(element, reflex) {
    super.beforeReflex(element, reflex)
    if (reflex === 'Admin::B2b::Actions#create_shop') {
      this.createB2BShopTarget.disabled = true;
      this.createB2BShopTarget.innerHTML = "<p style='display: flex; align-items:center'>Creando... <span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true'></span></p>";
    }
  }
}